import axios from 'axios';

export class UsersService {
  async fetchAll() {
    return (await axios.get('/users')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/users/' + id)).data;
  }

  async update(user) {
    return (await axios.patch('/users', user)).data;
  }

  async create(user) {
    return (await axios.post('/users', user)).data;
  }

  async delete(user) {
    return (await axios.delete(`/users/${user.id}/${user.username}`)).data;
  }

  async addBulkUsers(users) {
    return (await axios.post('/user-stats/add-bulk-users', users)).data;
  }

  async addSingleUser(user) {
    return (await axios.post('/user-stats/add-individual-users', user)).data;
  }

  async resendIndividualUsersPassword(user) {
    return (
      await axios.post('/user-stats/resend-individual-users-password', user)
    ).data;
  }

  async logoutUser(id) {
    return (await axios.post('users/revoke-token', { uid: id })).data;
  }
}
