<template>
  <div id="background">
    <v-card class="sign-in" elevation="10">
      <img src="../../assets/logo_alt.png" alt="Logo" class="sign-in__logo"/>

      <h1 class="sign-in__title">Set your password</h1>
      <v-form ref="form">
        <v-text-field
            v-model="password"
            :rules="[required('Do not leave this field empty')]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keypress.enter="resetPassword"
            label="Password"
            outlined
        />

        <v-text-field
            v-model="confirmPassword"
            :rules="[required('Do not leave this field empty')]"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @keypress.enter="resetPassword"
            label="Confirm Password"
            outlined
        />

        <v-btn
            @click="resetPassword"
            color="#da57a7"
            dark
            elevation="0"
            width="100%"
            height="45px"
        >
          Set Password
          <v-icon small style="margin-left: 5px">mdi-arrow-right</v-icon>
        </v-btn>
      </v-form>
    </v-card>
    <loading-dialog
        v-model="loading"
        message="Setting new password, Please wait..."
    />
    <error-dialog v-model="error" :error="errorVal" :show-action="showErrorAction"/>
  </div>
</template>

<script>
import {required, email} from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import axios from "axios";

export default {
  name: 'AdminUserInvite',
  components: {ErrorDialog, LoadingDialog},

  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    user: null,
    error: false,
    showErrorAction: true,
    errorVal: {},
    loading: false,
    password: '',
    confirmPassword: ''
  }),

  async mounted() {
    try {
      const query = this.$route.query;
      if (query.token) {
        this.user = (await this.$axios.get(`/users/${query.token}`)).data;

        const createdAt = new Date(this.user.createdAt);
        const now = new Date();
        const timeDiff = (now - createdAt) / (1000 * 60 * 60); // Convert ms to hours
        if (timeDiff > 24) {
          this.error = true;
          this.showErrorAction = false;
          this.errorVal = {
            title: 'Invitation Expired',
            description: 'The invitation link has expired. Please contact the admin for a new link.'
          };
        }
      } else {
        this.$router.push('/').then(() => this.$router.go());
      }
    } catch (e) {
      this.error = true;
      this.showErrorAction = false;
      this.errorVal = {
        title: 'Invitation link is expired or invalid!',
        description: 'Please! Contact the admin to get a new link.'
      };
    }
  },

  methods: {
    email,
    required,
    async resetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.password !== this.confirmPassword) {
            this.error = true;
            this.showErrorAction = true;
            this.errorVal = {
              title: 'Error while setting password',
              description: 'Passwords do not match!'
            };
            this.loading = false;
            return;
          }

          const data = {
            id: this.user.id,
            firebaseId: this.user.firebaseId,
            password: this.password
          }
          await axios.patch('/users/reset-password', data)
          await this.$router.push('/').then(() => this.$router.go());
        } catch (e) {
          this.showErrorAction = true;
          this.error = true;
          this.errorVal = {
            title: 'Error while setting password',
            description: e.error || e.message || "Something went wrong!"
          };
          this.loading = false;
        }

        this.loading = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  background: white
  text-align: center
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 30%
    margin-bottom: 20px

  &__title
    color: black
    font-size: 24px
    margin-bottom: 10px
    font-weight: normal
    font-family: google-sans, sans-serif

  &__message
    font-size: 13px
    margin-bottom: 40px


#background
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background: conic-gradient(from 270deg, #da57a7 0deg, #da57a7 60deg, #70aad5 60deg, #70aad5 120deg, #ee8782 120deg, #ee8782 180deg, #7fd7b6 180deg, #7fd7b6 240deg, #f5c187 240deg, #f5c187 300deg, #495db7 300deg, #495db7 360deg)

//.logo-box {
//  padding: 10px;
//  margin: -120px auto 30px auto;
//}
//
//.heading {
//  font-size: 24px;
//  font-weight: normal;
//  margin-bottom: 20px;
//  font-family: google-sans, sans-serif;
//}
</style>

<style>
html {
  overflow-y: auto;
}
</style>
