<template>
  <v-card>
    <v-tabs v-model="tab" class="mb-4" color="primary">
      <v-tab v-for="(type, index) in types" :key="index">
        {{ type }}
      </v-tab>
    </v-tabs>
    <v-form v-model="valid">
      <data-table
        :key="dataTableKey"
        :allow-add="false"
        :headers="headers"
        :loader="loadData"
        title="In App Products"
        @done="$router.back()"
      >
        <template #primary-action>
          <v-btn
            :disabled="!getUser().scopes.includes('ai-tutor-iaps:edit')"
            color="primary"
            elevation="0"
            @click="save"
          >
            <v-icon>mdi-disk</v-icon>
            Save
          </v-btn>

          <v-btn
            v-if="tab === 1"
            :disabled="!getUser().scopes.includes('iaps:edit')"
            class="ml-2"
            color="primary"
            elevation="0"
            @click="mobileAppBannerDialoge = true"
            >Mobile App Banner
            <v-icon
              :color="
                mobileAppBanner &&
                mobileAppBanner.is_active === true &&
                mobileAppBanner.sale_period > mobileAppBanner.currentDate
                  ? 'yellow'
                  : 'red'
              "
              class="ml-2"
              x-small
            >
              mdi-circle
            </v-icon>
          </v-btn>
        </template>
        <template #sku="{ item }">
          {{ item.product.sku }}
        </template>
        <template #title="{ item }">
          {{ item.product.listings['en-US'].title }}
        </template>
        <template #description="{ item }">
          {{ item.product.listings['en-US'].description }}
        </template>

        <template #price="{ item }">
          <v-text-field
            v-model="item.price"
            :rules="[(v) => (!!v && +v > 0) || 'Price must be provided']"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>

        <template v-if="tab === 0" #discount_price_android="{ item }">
          <v-text-field
            v-model="item.discount_price_android"
            :rules="[
              (v) =>
                checkLess(v, item.price) || 'Discount must be less than Price'
            ]"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>

        <template v-if="tab === 0" #discount_mode_android="{ item }">
          <v-checkbox
            v-model="item.discount_mode_android"
            hide-details
            class="mb-4"
          />
        </template>

        <template v-if="tab === 1" #discount_price="{ item }">
          <v-text-field
            v-model="item.discount_price"
            :rules="[
              (v) =>
                checkLess(v, item.price) || 'Discount must be less than Price'
            ]"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>

        <template v-if="tab === 1" #discount_mode="{ item }">
          <v-checkbox v-model="item.discount_mode" hide-details class="mb-4" />
        </template>

        <template #mobile="{ item }" v-if="tab === 2">
          {{ item.value }}%</template
        >
        <template #mobile="{ item }" v-else-if="tab === 3">{{
          item.value
        }}</template>

        <template #hide="{ item }">
          <v-checkbox
            v-model="item.isActive"
            color="primary"
            label="Active"
          ></v-checkbox>
        </template>

        <template #action="{ item }">
          <v-icon
            v-if="getUser().scopes.includes('ai-tutor-iaps-offers:view')"
            color="green"
            small
            @click="edit(item)"
            >mdi-pencil
          </v-icon>
        </template>
      </data-table>

      <v-dialog v-model="mobileAppBannerDialoge" width="500">
        <v-card class="py-2">
          <v-card-title class="mb-4">Mobile App Banner</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="mobileAppBanner.name"
              :rules="[required()]"
              dense
              label="Name"
              outlined
            />
            <v-text-field
              v-model="mobileAppBanner.description"
              :rules="[required()]"
              dense
              label="Description"
              outlined
            />
            <v-text-field
              v-model="mobileAppBanner.percentage_off"
              :rules="[required()]"
              dense
              label="Percentage Off"
              outlined
            />

            <DateTimePicker
              v-model="mobileAppBanner.sale_period"
              :rules="[required()]"
              label="Sale Period"
              timeFormat="hh:mm:ss a"
            >
            </DateTimePicker>

            <v-checkbox
              v-model="mobileAppBanner.is_active"
              :label="'Active'"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="primary" @click="submitMobileBanner()" elevation="0"
              >Save</v-btn
            >
            <v-btn color="primary" @click="closeMobileBannerModal" outlined
              >Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <loading-dialog v-model="editLoading" message="Updating Products" />
      <loading-dialog v-model="loading" message="Please wait..." />
    </v-form>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { IapService } from '@/services/iap-service';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import DateTimePicker from '@/components/settings/DateTimePicker';

const { format, parseISO } = require('date-fns');

const commonHeaders = [
  {
    text: 'SKU',
    value: 'sku',
    sortable: false
  },
  {
    text: 'Title',
    value: 'title',
    sortable: false
  },
  {
    text: 'Description',
    value: 'description',
    sortable: false
  },
  {
    text: 'Price',
    value: 'price'
  }
];

export default {
  components: { LoadingDialog, DataTable, DateTimePicker },

  data: () => ({
    editLoading: false,
    editDialog: false,

    dataTableKey: null,

    mobileAppBannerDialoge: false,
    mobileAppBanner: {},

    tab: null,
    types: ['Android', 'IOS', 'Spin the Wheel(IOS)', 'Spin the Wheel(Android)'],

    valid: false,
    loading: false,
    service: new IapService(),

    headers: [],
    spinTheWheelIosItems: [],
    spinTheWheelIosItemsCoins: [],
    spinTheWheelAndroidItems: [],
    spinTheWheelAndroidItemsCoins: [],
    spinTheWheelData: {}
  }),

  watch: {
    tab(value) {
      this.dataTableKey += 1;
      if (value === 0) {
        this.headers = [...commonHeaders];
        this.headers.push(
          {
            text: 'Discounted Price',
            value: 'discount_price_android'
          },
          {
            width: 100,
            text: 'Discount Active',
            value: 'discount_mode_android'
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false
          }
        );
      } else if (value === 1) {
        this.getMobileAppBanner();
        this.headers = [...commonHeaders];
        this.headers.push(
          {
            text: 'Discounted Price',
            value: 'discount_price'
          },
          {
            width: 100,
            text: 'Discount Active',
            value: 'discount_mode'
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false
          }
        );
      } else if (value === 2) {
        this.headers = [
          {
            text: 'Percentage',
            value: 'mobile',
            sortable: false
          },
          {
            text: 'Action',
            value: 'hide',
            sortable: false
          }
        ];
      } else if (value === 3) {
        this.headers = [
          {
            text: 'Days',
            value: 'mobile',
            sortable: false
          },
          {
            text: 'Action',
            value: 'hide',
            sortable: false
          }
        ];
      }
    }
  },

  methods: {
    required,
    getUser,

    async getMobileAppBanner() {
      await fetch(this.$aiTutorApiUrl + '/sales/')
        .then((res) => res.json())
        .then((res) => {
          this.setBannersDetails(res);
        });
    },

    async closeMobileBannerModal() {
      await fetch(this.$aiTutorApiUrl + '/sales/')
        .then((res) => res.json())
        .then((res) => {
          this.setBannersDetails(res);
        });

      this.mobileAppBannerDialoge = false;
    },

    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },

    setBannersDetails(response) {
      const mobileBannerIndex = response.sales.findIndex(
        (sale) => sale.platform === 0
      );

      if (mobileBannerIndex !== -1) {
        const mobileBanner = response.sales[mobileBannerIndex];

        this.mobileAppBanner = {
          ...mobileBanner,
          sale_period: this.formateDate(
            mobileBanner.sale_period,
            'yyyy-MM-dd hh:mm:ss a'
          ),
          currentDate: this.formateDate(
            new Date().toISOString(),
            'yyyy-MM-dd hh:mm:ss a'
          )
        };

        this.dataTableKey += 1;
      }
    },

    async submitMobileBanner() {
      this.loading = true;
      const date = new Date(this.mobileAppBanner.sale_period);
      const clonedBanner = {
        ...this.mobileAppBanner,
        sale_period: date.toISOString(),
        platform: 0
      };

      this.items = this.items.map((item) => {
        return {
          ...item,
          discountMode:
            item.product.sku !== 'lifetime_new' &&
            item.product.listings['en-US'].title !== 'Lifetime'
              ? clonedBanner.is_active
              : item.discountMode,
          discountPrice:
            item.product.sku !== 'lifetime_new' &&
            item.product.listings['en-US'].title !== 'Lifetime'
              ? item.price - (item.price * clonedBanner.percentage_off) / 100
              : item.discountPrice
        };
      });

      try {
        await fetch(this.$aiTutorApiUrl + '/sales/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(clonedBanner)
        });

        this.$toast.success(
          'Sales Banner and Discount except Lifetime updated successfully!'
        );

        this.dataTableKey += 1;
      } catch (error) {
        this.$toast.error('Error occurred while updating Sales Banner');
      }

      try {
        for (const item of this.items) {
          if (!item.offers) {
            item.offers = [];
          }

          await fetch(this.$aiTutorApiUrl + '/iap/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
          });
        }

        this.closeMobileBannerModal();

        this.$toast.success('Products updated successfully');

        this.dataTableKey += 1;
        document.getElementById('refresh').click();
      } catch (error) {
        this.$toast.error('Error occurred while updating products');
      }

      this.loading = false;

      await fetch(this.$aiTutorApiUrl + '/sales/')
        .then((res) => res.json())
        .then((res) => {
          this.setBannersDetails(res);
        });
      this.dataTableKey += 1;
    },

    checkLess(discount, price) {
      //eslint-disable-next-line no-extra-boolean-cast
      if (!!discount) {
        //eslint-disable-next-line no-extra-boolean-cast
        return parseInt(discount) < parseInt(!!price ? price : '0');
      } else {
        return true;
      }
    },

    edit(item) {
      // for android
      if (this.tab === 0)
        this.$router.push(`/ai-tutor/iaps/offers?id=${item.sku}&p=1`);

      // for iso
      if (this.tab === 1)
        this.$router.push(`/ai-tutor/iaps/offers?id=${item.sku}&p=0`);
    },

    async save() {
      if (this.tab === 2 || this.tab === 3) {
        const type = this.tab === 2 ? 2 : 1;

        let data =
          this.tab === 2
            ? this.spinTheWheelIosItems
            : this.spinTheWheelAndroidItems;
        data =
          data.length > 0 &&
          data
            .filter((item) => item.isActive)
            .map((item) => ({
              type,
              value: item.value
            }));

        const existingData =
          this.spinTheWheelData.mobile &&
          this.spinTheWheelData.mobile.filter((item) => item.type !== type);

        this.spinTheWheelData.mobile = [...data, ...(existingData || [])];

        if (
          this.spinTheWheelData.mobile.length < 3 ||
          this.spinTheWheelData.mobile.length > 7
        ) {
          return this.$toast.error(
            this.tab === 2
              ? 'Please select a minimum of 3 and a maximum of 7 items from spin the wheel IOS'
              : 'Please select a minimum of 3 and a maximum of 7 items from spin the wheel Android'
          );
        }

        const rearrangedArray = [];
        while (this.spinTheWheelData.mobile.length > 0) {
          rearrangedArray.push(this.spinTheWheelData.mobile.shift());
          rearrangedArray.push(
            this.spinTheWheelData.mobile.splice(
              this.spinTheWheelData.mobile.findIndex(
                (item) =>
                  item.type !== rearrangedArray[rearrangedArray.length - 1].type
              ),
              1
            )[0]
          );
        }

        this.spinTheWheelData.mobile = rearrangedArray.filter(
          (item) => item !== undefined
        );

        this.editLoading = true;
        await fetch(this.$aiTutorApiUrl + '/spin-of-the-wheel/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.spinTheWheelData)
        });
        this.editLoading = false;

        return this.$toast.success('Spin the wheel changes saved successfully');
      }

      this.editLoading = true;
      for (const item of this.items) {
        item.price = +item.price;

        if (this.tab === 0) {
          item.discount_price_android = +item.discount_price_android;
        } else {
          item.discount_price = +item.discount_price;
        }

        if (!item.offers) {
          item.offers = [];
        }

        try {
          await fetch(this.$aiTutorApiUrl + '/iap/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
          });
        } catch (err) {
          this.$toast.error('Some error occured');
        }
      }
      this.$toast.success('Products updated successfully');
      this.editLoading = false;
    },

    async loadData() {
      // Android Tab
      if (this.tab === 0) {
        return (this.items = await fetch(
          this.$aiTutorApiUrl + '/iap/?platform=1'
        ).then((res) => res.json()));
      }

      // IOS Tab
      if (this.tab === 1) {
        return (this.items = await fetch(
          this.$aiTutorApiUrl + '/iap/?platform=0'
        ).then((res) => res.json()));
      }

      // STW (IOS)
      if (this.tab === 2) {
        const data = await this.loadSpinTheWheelData(0, 2);
        this.spinTheWheelIosItems = data;
        return data;
      }

      // STW (Android)
      if (this.tab === 3) {
        const data = await this.loadSpinTheWheelData(1, 1);
        this.spinTheWheelAndroidItems = data;
        return data;
      }
    },

    async loadSpinTheWheelData(platform, type) {
      const spinTheWheelData = await fetch(
        this.$aiTutorApiUrl + `/spin-of-the-wheel/?platform=${platform}`
      ).then((res) => res.json());
      this.spinTheWheelData = spinTheWheelData[0] || {};

      let data = this.spinTheWheelData.mobile || [];
      data = data.filter((item) => item.type === type);

      const startValue = type === 1 ? 3 : 10;
      const endValue = type === 1 ? 15 : 70;

      const finalItems = [];
      for (
        let i = startValue;
        i <= endValue;
        type === 1 ? (i += 2) : (i += startValue)
      ) {
        const index = data.findIndex((item) => item.value === i);

        const item = {
          value: i,
          isActive: index >= 0
        };

        finalItems.push(item);
      }

      return finalItems;
    }
  }
};
</script>
