import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import FlowyPlugin from '@hipsjs/flowy-vue';
import router from './router';
import axios from 'axios';
import { firestorePlugin } from 'vuefire';
import VueChartJs from 'vue-chartjs';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import OneSignalVue from 'onesignal-vue';
import Vue2Editor from 'vue2-editor';
import VuePapaParse from 'vue-papa-parse';
import { registerPlugin } from 'filepond';
import './assets/style.sass';
import '@hipsjs/flowy-vue/dist/lib/flowy-vue.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.css';
// vue-toastification
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
Vue.prototype.$aiTutorApiUrl = process.env.VUE_APP_AI_TUTOR_API_BASE_URL;
Vue.prototype.$vuetify = vuetify;

axios.defaults.baseURL = process.env.VUE_APP_MAIN_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token;
      config.headers.common['aut'] = 1;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(axios(prom.requestConfig));
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log('Interceptor Error:', error);

    if (!error.response || !error.response.status) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    // Prevent infinite retry loop by checking if request has already been retried
    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refresh_token = localStorage.getItem('auth_refresh_token');

      if (!refresh_token) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('auth_refresh_token');
        return Promise.reject(error);
      }

      if (!isRefreshing) {
        isRefreshing = true;
        try {

          const { data: token } = await axios.post('/users/refresh-token', {
            refreshToken: refresh_token
          });

          localStorage.setItem('auth_token', token.id_token);
          localStorage.setItem('auth_refresh_token', token.refresh_token);

          isRefreshing = false;

          processQueue(null, token.id_token);

          originalRequest._retry = true; // Mark request as retried
          originalRequest.headers['Authorization'] = `Bearer ${token.id_token}`

          return axios(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          localStorage.removeItem('auth_token');
          localStorage.removeItem('auth_user');
          localStorage.removeItem('auth_refresh_token');
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({ requestConfig: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
});
Vue.use(FlowyPlugin);
Vue.use(OneSignalVue);
Vue.use(VueChartJs);
Vue.use(firestorePlugin);
Vue.use(Vue2Editor);
Vue.use(VuePapaParse);

new Vue({
  vuetify,
  FlowyPlugin,
  VueChartJs,
  router,
  render: (h) => h(App)
}).$mount('#app');
