<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="
        getUser().scopes.includes('studentGroups:all') ? headers1 : headers2
      "
        title="Student Groups"
        :allow-add="
        getUser() ? getUser().scopes.includes('studentGroups:new') : false
      "
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="
        getUser()
          ? getUser().scopes.includes('studentGroups:delete')
            ? deleteItem
            : null
          : null
      "
        :edit-handler="
        getUser()
          ? getUser().scopes.includes('studentGroups:edit')
            ? edit
            : null
          : null
      "
        :view-handler="
        getUser()
          ? getUser().scopes.includes('studentGroups:view')
            ? view
            : null
          : null
      "
    >
      <template #extra-actions="{ item }">
        <v-icon
            small
            color="primary"
            @click="showLeaderboard(item)"
            title="Leaderboard"
        >
          mdi-trophy
        </v-icon>
        <v-icon small color="primary" @click="showStats(item)" title="Stats">
          mdi-chart-bar
        </v-icon>
      </template>
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #admins="{ item }">
        <v-chip
            v-for="(admin, index) in item.admins"
            :key="index"
            small
            color="primary"
            class="ma-1"
        >
          {{ admin }}
        </v-chip>
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import {getUser} from '@/utils/local';
import {StudentGroupsService} from '@/services/student-groups-service';
import {UsersService} from '@/services/users-service';
import dayjs from 'dayjs';

export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new StudentGroupsService(),
    userServices: new UsersService(),
    adminCache: {},

    headers1: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Admins',
        value: 'admins',
        sortable: false
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ],

    headers2: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ]
  }),

  methods: {
    getUser,

    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY HH:mm A');
    },

    edit(item) {
      this.$router.push(`/student-group?id=${item.id}`);
    },

    deleteItem(item) {
      this.service.delete(item);
    },

    addNew() {
      this.$router.push('/student-group');
    },

    view(item) {
      this.$router.push(`/customers?studentGroupId=${item.id}`);
    },

    showLeaderboard(item) {
      this.$router.push(`/student-groups/leaderboard/${item.id}`);
    },

    showStats(item) {
      this.$router.push(`/stats?sgId=${item.id}`);
    },

    async loadData() {
      this.adminCache = {};
      let data = await this.service.fetchAll();

      if (!this.getUser().scopes.includes('studentGroups:all')) {
        data = data.filter((item) => {
          return item.admins?.includes(this.getUser().id);
        });
      }

      for (const item of data) {
        item.admins = item.admins || []

        const updatedAdmins = []

        for (const id of item.admins) {

          if (this.adminCache[id] !== undefined) {
            updatedAdmins.push(this.adminCache[id]);
          } else {
            try {
              const user = await this.userServices.fetchOne(id);
              this.$set(this.adminCache, id, user.username);
              this.adminCache = {...this.adminCache};
              updatedAdmins.push(user.username);
            } catch (e) {
              this.$set(this.adminCache, id, null);
            }
          }

        }

        item.admins = updatedAdmins;

      }

      return data;
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
